.leaflet-geosearch-bar {
    width: 70%;
}

.leaflet-touch .leaflet-geosearch-bar form input {
    height: 50px;
    font-size: 0.9rem;
}

.leaflet-touch .leaflet-control-geosearch button.reset {
    line-height: 50px;
    font-size: 1.5rem;
    font-weight: 400;
    width: 50px;
    height: 50px;
}

.leaflet-touch .leaflet-control-geosearch .active button.reset {
    color: #e70021;
}

.leaflet-control-geosearch .results {
    font-size: 0.9rem;
}

.leaflet-control-geosearch .results > * {
    padding: 0.25rem 0.5rem;
}

.leaflet-popup-content {
    margin: 13px 40px 13px 20px;
}

.leaflet-container a.leaflet-popup-close-button {
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #e70021;
    font: 1rem sans-serif;
}

.leaflet-container a.leaflet-popup-close-button > span {
    display: block;
    width: 100%;
    height: 100%;
    line-height: 40px;
    font-size: 1.25rem;
}

.leaflet-marker-icon svg {
    filter: drop-shadow(2px 2px 2px rgb(255 255 255 / 0.3));
}

.leaflet-control-locate-location-arrow {
    background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"><path d="M0 0h48v48h-48z" fill="none"/><path d="M24 16c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm17.88 6c-.92-8.34-7.54-14.96-15.88-15.88v-4.12h-4v4.12c-8.34.92-14.96 7.54-15.88 15.88h-4.12v4h4.12c.92 8.34 7.54 14.96 15.88 15.88v4.12h4v-4.12c8.34-.92 14.96-7.54 15.88-15.88h4.12v-4h-4.12zm-17.88 16c-7.73 0-14-6.27-14-14s6.27-14 14-14 14 6.27 14 14-6.27 14-14 14z"/></svg>') !important;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}
